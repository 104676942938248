import React, { useCallback, useState, useEffect, useRef } from "react";
import Icon from "../../general/Icon/Icon";
import styles from "./Dropdown.module.scss";

export type DropdownOption = {
  id: string;
  title: string;
};

interface DropdownProps {
  options: Array<DropdownOption>;
  className?: string;
  optionsClassName?: string;
  wrapperClassName?: string;
  arrowClassName?: string;
  onChange: (selectedOptionValue: string) => void;
  placeholder?: string;
  openDirection?: "up" | "down";
  value?: string;
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  className,
  optionsClassName,
  wrapperClassName,
  arrowClassName,
  onChange,
  placeholder = "Select",
  openDirection = "down",
  value,
}) => {
  const ddlRef = useRef<HTMLDivElement>(null);

  const [dropdownActive, setDropdownActive] = useState<boolean>(false);

  const updateDropdownState = useCallback(() => {
    setDropdownActive(!dropdownActive);
  }, [dropdownActive]);

  const [currentValue, setCurrentValue] = useState(value);

  const handleOptionSelect = useCallback(
    (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      const selectedOptionValue = event.currentTarget.dataset["option"];
      setCurrentValue(selectedOptionValue);
      setDropdownActive(false);
      onChange(selectedOptionValue || "all");
    },
    [onChange],
  );

  useEffect(() => {
    const clickListener = (evt: Event) => {
      if (evt.target === ddlRef.current) {
        // Box itself was clicked, do not close
        return;
      } else {
        let checkEl = evt.target as HTMLElement;
        if (checkEl.parentElement) {
          while (checkEl.parentElement) {
            checkEl = checkEl.parentElement;
            if (checkEl === ddlRef.current) {
              // clicked inside the box
              return;
            }
          }
        }
        // If we clicked anywhere outside of the box, we close
        setDropdownActive(false);
      }
    };

    if (dropdownActive) {
      document.addEventListener("click", clickListener, true);
    } else {
      document.removeEventListener("click", clickListener, true);
    }
    return () => {
      document.removeEventListener("click", clickListener, true);
    };
  }, [ddlRef, dropdownActive]);

  return (
    <div className={`${styles.dropdown} ${className}`} ref={ddlRef}>
      <div className={`${styles.dropdownWrapper} ${wrapperClassName}`} onClick={updateDropdownState}>
        <span>{currentValue ? options.find((o) => o.id === currentValue)?.title : placeholder}</span>
        <Icon
          className={`${styles.arrow} ${arrowClassName} ${dropdownActive ? styles.rotateIcon : ""}`}
          name="Chevron"
        />
      </div>
      {dropdownActive && (
        <div
          className={`${styles.dropdownOptions} ${dropdownActive && styles.active} ${optionsClassName}`}
          data-open-direction={openDirection}
        >
          {options
            .filter(({ id }) => id !== value)
            .map((option) => (
              <span key={option.id} className={styles.option} onClick={handleOptionSelect} data-option={option.id}>
                {option.title}
              </span>
            ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
