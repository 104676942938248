import { ChampionListCard } from "../../content/cards/ChampionListCard/ChampionListCard";
import styles from "./ChampionList.module.scss";
import { graphql } from "gatsby";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ContentstackChampionCard } from "../../../data/types/Contentstack";
import { gsap } from "gsap";

export type ChampionListProps = {
  championList: Array<ContentstackChampionCard>;
};

export const ChampionList: React.FC<ChampionListProps> = ({ championList }) => {
  const [currentChampionList, setCurrentChampionList] = useState<Array<ContentstackChampionCard>>(championList);
  const listRef = useRef<HTMLUListElement | null>(null);

  const transitionIn = (container: HTMLUListElement | null) => {
    if (!container) return;
    const timeline = gsap.timeline();
    const childElements = [...container?.children];

    gsap.killTweensOf(childElements);

    timeline.set(container, { css: { visibility: "visible" } });
    timeline.fromTo(
      container,
      { y: 100, autoAlpha: 0 },
      {
        y: 0,
        autoAlpha: 1,
        duration: 0.3,
        ease: "expo.out",
      },
    );
    timeline.fromTo(
      childElements,
      { yPercent: 10, autoAlpha: 0 },
      { yPercent: 0, autoAlpha: 1, stagger: 0.1, duration: 0.3, ease: "power1.out" },
    );
  };

  const transitionOut = useCallback(
    (container: HTMLUListElement | null) => {
      if (!container) return;
      const childElements = [...container?.children];
      gsap.killTweensOf(childElements);

      gsap.fromTo(
        container,
        { y: 0, autoAlpha: 1 },
        {
          y: 100,
          autoAlpha: 0,
          duration: 0.3,
          ease: "expo.out",
          onComplete: () => {
            setCurrentChampionList(championList);
          },
        },
      );
    },
    [championList],
  );

  useEffect(() => {
    if (championList === currentChampionList) return;
    transitionOut(listRef.current);
  }, [championList, currentChampionList, transitionOut]);

  useEffect(() => {
    if (championList !== currentChampionList) return;
    transitionIn(listRef.current);
  }, [championList, currentChampionList]);

  return (
    <section className={`container ${styles.championListWrapper}`}>
      <div className={`content-wrapper light`}>
        <ul className={styles.championList} ref={listRef}>
          {currentChampionList.map((champion, index) => (
            <li key={`${champion.id}-${index}`} className={styles.championCardItem}>
              <ChampionListCard
                className={styles.championListCard}
                cardContent={champion}
                href={champion.url.url}
                index={index}
              />
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment ChampionList on Contentstack_champion_list {
    championList: champion_select {
      name: title
      image: champion_thumbnail {
        ...ContentstackAsset
      }
      difficultyLevel: difficulty_level
      roles: champion_role {
        ...ContentstackChampionRole
      }
      url {
        url
      }
    }
  }
`;
