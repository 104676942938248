import { gsap } from "gsap";
import DrawSVGPlugin from "../../../vendor/gsap/DrawSVGPlugin.js";

gsap.registerPlugin(DrawSVGPlugin);

export const animateOptions = (element: HTMLUListElement | null) => {
  if (!element) return;
  const tl = gsap.timeline();
  const childElements = [...element?.children];

  tl.from(childElements, { yPercent: 20, autoAlpha: 0, stagger: 0.1, ease: "expo.out" });
  return tl;
};
export const fadeIn = (element: HTMLElement | null, direction: "left" | "right" = "left", delay = 0) => {
  gsap.set(element, { css: { visibility: "visible" } });

  switch (direction) {
    case "left":
      gsap.from(element, { xPercent: -5, autoAlpha: 0, duration: 0.8, ease: "power1.out", delay });
      break;
    case "right":
      gsap.from(element, { xPercent: 5, autoAlpha: 0, duration: 0.8, ease: "power1.out", delay });
      break;
    default:
      break;
  }
};

export const animateFilter = (
  rolesListContainer: HTMLUListElement | null,
  roleContainer: HTMLDivElement | null,
  difficultyContainer: HTMLDivElement | null,
) => {
  if (!roleContainer || !difficultyContainer) return;
  const roleDashDetail = roleContainer.children[1];
  const roleFrame = roleContainer.firstChild?.firstChild?.firstChild as SVGPathElement;
  const parentElement = roleContainer.parentNode;
  let roleDropdown = null;
  if (!rolesListContainer) {
    roleDropdown = roleContainer.lastChild as HTMLDivElement;
  }
  const difficultyDashDetail = difficultyContainer.children[1];
  const difficultyFrame = difficultyContainer.firstChild?.firstChild?.firstChild as SVGPathElement;
  const difficultyDropdown = difficultyContainer.lastChild as HTMLDivElement;

  const tl = gsap.timeline({ defaults: { clearProps: "all" } });

  tl.set(parentElement, { css: { visibility: "visible" } });
  tl.from(roleFrame, { drawSVG: "0%", duration: 0.8 }, 0)
    .from(roleDashDetail, { autoAlpha: 0, duration: 0.2 })
    .from(roleDashDetail, { height: 0, duration: 0.4 });
  if (roleDropdown) {
    tl.from(roleDropdown, { yPercent: 20, autoAlpha: 0, duration: 1, ease: "expo.out" }, 1);
  }
  tl.add(animateOptions(rolesListContainer) as gsap.core.Timeline, 0.8)
    .from(difficultyFrame, { drawSVG: "0%", duration: 0.8 }, 0.5)
    .from(difficultyDashDetail, { autoAlpha: 0, duration: 0.2 }, 1)
    .from(difficultyDashDetail, { height: 0, duration: 0.4 }, 1.2)
    .from(difficultyDropdown, { yPercent: 20, autoAlpha: 0, duration: 1, ease: "expo.out" }, 1.5);
};
