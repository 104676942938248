import { graphql } from "gatsby";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { ChampionDifficultyLevel, ChampionRole } from "../../../data/types/Contentstack";
import { useIsMobile } from "../../../hooks/useIsMobile";
import DetailLine from "../../general/borders/DetailLine/DetailLine";
import TitleCopyWrapper from "../../general/borders/TitleCopyWrapper/TitleCopyWrapper";
import Dropdown from "../../general/Dropdown/Dropdown";
import Icon from "../../general/Icon/Icon";
import SectionTitle from "../../general/SectionTitle/SectionTitle";
import styles from "./ChampionFilter.module.scss";
import { animateFilter, fadeIn } from "./animations";
import { useInView } from "react-intersection-observer";

export type ChampionFilterOption = {
  name: string;
  machineName: string;
};
export interface ChampionFilterProps {
  titleParts: Array<string>;
  description: string;
  rolesData: {
    allRolesText: string;
    roles: Array<ChampionFilterOption>;
  };
  difficultiesData: {
    allDifficultiesText: string;
    difficultyLevels: Array<ChampionFilterOption>;
  };
}
interface ChampionFilterExtendedProps extends ChampionFilterProps {
  selectedRole: ChampionRole | "all";
  selectedDifficulty: ChampionDifficultyLevel | "all";
  onChangeRole: (role: ChampionRole) => void;
  onChangeDifficulty: (difficulty: ChampionDifficultyLevel) => void;
}
export const ChampionFilter: React.FC<ChampionFilterExtendedProps> = ({
  onChangeRole,
  selectedRole,
  description,
  selectedDifficulty,
  onChangeDifficulty,
  rolesData,
  titleParts,
  difficultiesData,
}) => {
  const isMobile = useIsMobile();
  const rolesWrapperRef = useRef<HTMLDivElement | null>(null);
  const difficultyWrapperRef = useRef<HTMLDivElement | null>(null);
  const rolesListRef = useRef<HTMLUListElement | null>(null);
  const descriptionRef = useRef<HTMLParagraphElement | null>(null);
  const eyebrowRef = useRef<HTMLHeadingElement | null>(null);
  const [observerRef, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      fadeIn(eyebrowRef.current, "right");
      animateFilter(rolesListRef.current, rolesWrapperRef.current, difficultyWrapperRef.current);
      fadeIn(descriptionRef.current, "left", 1.5);
    }
  }, [inView]);

  const roles = useMemo(
    () => [
      {
        name: rolesData.allRolesText,
        machineName: "all",
      },
      ...rolesData.roles,
    ],
    [rolesData],
  );

  const difficulties = useMemo(
    () => [
      {
        name: difficultiesData.allDifficultiesText,
        machineName: "all",
      },
      ...difficultiesData.difficultyLevels,
    ],
    [difficultiesData],
  );

  const dropdownRolesOptions = useMemo(() => roles.map(({ name, machineName }) => ({ id: machineName, title: name })), [
    roles,
  ]);
  const dropdownDifficultiesOptions = useMemo(
    () => difficulties.map(({ name, machineName }) => ({ id: machineName, title: name })),
    [difficulties],
  );

  const onRoleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      onChangeRole(event.currentTarget.dataset.key as ChampionRole);
    },
    [onChangeRole],
  );
  const onChangeRoleSelect = useCallback(
    (selectedRole: string) => {
      onChangeRole(selectedRole as ChampionRole);
    },
    [onChangeRole],
  );
  const onChangeDifficultySelect = useCallback(
    (selectedDifficulty: string) => {
      onChangeDifficulty(selectedDifficulty as ChampionDifficultyLevel);
    },
    [onChangeDifficulty],
  );
  return (
    <section className={`container ${styles.championFilter}`} ref={observerRef}>
      <div className={"content-wrapper light"}>
        <TitleCopyWrapper className={styles.titleWrapper}>
          <DetailLine className={styles.detailLine} dashDetailClassName={styles.dashDetailWrapper} variation="left">
            <h2 ref={eyebrowRef} className={`heading-09 font-normal ${styles.eyebrow}`}>
              {titleParts[0]}
            </h2>
            <SectionTitle className={styles.title} titleParts={[titleParts[1]]} />
          </DetailLine>
        </TitleCopyWrapper>
        <div className={styles.filterWrapper}>
          <div className={styles.roleSelectionWrapper} ref={rolesWrapperRef}>
            <Icon className={styles.rolesFrame} name={"ChampsFilterFrameLeft"} />
            <span className={styles.dashDetail} />
            {isMobile ? (
              <Dropdown
                className={styles.roleDropdown}
                wrapperClassName={styles.dropdownStyles}
                optionsClassName={styles.roleDropdownOptionsStyles}
                onChange={onChangeRoleSelect}
                options={dropdownRolesOptions}
                placeholder={dropdownRolesOptions[0].title}
                value={selectedRole}
              />
            ) : (
                <ul className={styles.desktopRolesWrapper} ref={rolesListRef}>
                  {roles.map(({ name, machineName }, index) => (
                    <li
                      key={`${name}-${index}`}
                      className={`${styles.roleItemWrapper} ${selectedRole === machineName ? styles.isActive : ""}`}
                    >
                      <button
                        key={machineName}
                        className={`label-04 ${styles.filterOption}`}
                        onClick={onRoleClick}
                        data-key={machineName}
                      >
                        {name}
                      </button>
                    </li>
                  ))}
                </ul>
              )}
          </div>
          <div className={styles.difficultySelectionWrapper} ref={difficultyWrapperRef}>
            <Icon className={styles.difficultiesDropdownFrame} name={"ChampsFilterFrameRight"} />
            <span className={styles.dashDetail} />

            <Dropdown
              className={styles.difficultyDropdown}
              wrapperClassName={styles.dropdownStyles}
              optionsClassName={styles.difficultyDropdownOptionsStyles}
              onChange={onChangeDifficultySelect}
              options={dropdownDifficultiesOptions}
              placeholder={dropdownDifficultiesOptions[0].title}
              value={selectedDifficulty}
            />
          </div>
        </div>
        <p className={`copy-01 ${styles.description}`} ref={descriptionRef}>
          {description}
        </p>
      </div>
    </section>
  );
};
export const query = graphql`
  fragment ChampionFilter on Contentstack_champion_list {
    titleParts: champion_list_title
    description: champion_list_description
    rolesData: role_filters {
      allRolesText: all_roles
      roles {
        name: role_title
        machineName: role_machine_name
      }
    }
    difficultiesData: difficulty_dropdown {
      allDifficultiesText: all_difficulties
      difficultyLevels: difficulty_level {
        name: difficulty
        machineName: difficulty_machine_name
      }
    }
  }
`;
