import { graphql, PageProps } from "gatsby";
import React, { useMemo, useState } from "react";
import { ContentstackChampions } from "../data/types/ContentstackPages";
import { ChampionFilter } from "../components/block/ChampionFilter/ChampionFilter";
import { ChampionList } from "../components/block/ChampionList/ChampionList";
import { LatestReleases } from "../components/block/LatestReleases/LatestReleases";
import { ChampionDifficultyLevel, ChampionRole, ContentstackChampionCard } from "../data/types/Contentstack";
import championData from "../json/champion.json";

type ChampionsProps = {
  allContentstackChampions: { nodes: Array<ContentstackChampions> };
};

const Champions: React.FC<PageProps<ChampionsProps>> = ({
  // data: {
  //   allContentstackChampions: {
  //     nodes: [
  //       {
  //         championList,
  //         description,
  //         titleParts: championFilterTitleParts,
  //         rolesData,
  //         difficultiesData,
  //         latestReleases,
  //       },
  //     ],
  //   },
  // },
}) => {
  const {
    allContentstackChampions: {
      nodes: [
        {
          championList,
          description,
          titleParts: championFilterTitleParts,
          rolesData,
          difficultiesData,
          latestReleases,
        },
      ],
    },
  } = championData.result.data;
  const [selectedRole, setSelectedRole] = useState<ChampionRole>("all");
  const [selectedDifficulty, setSelectedDifficulty] = useState<ChampionDifficultyLevel>("all");

  const filteredChampions = useMemo(() => {
    if (selectedRole === "all" && selectedDifficulty === "all") return championList;
    return championList.reduce((acc: any[], champion: { roles: { machineName: any; }[]; difficultyLevel: string; }) => {
      if (
        (selectedRole === "all" || champion.roles.some(({ machineName }) => selectedRole === machineName)) &&
        (selectedDifficulty === "all" || selectedDifficulty === champion.difficultyLevel)
      )
        acc.push(champion);
      return acc;
    }, [] as Array<ContentstackChampionCard>);
  }, [selectedRole, championList, selectedDifficulty]);
  return (
    <>
      <LatestReleases latestReleases={latestReleases} />
      <ChampionFilter
        titleParts={championFilterTitleParts}
        description={description}
        rolesData={rolesData}
        difficultiesData={difficultiesData}
        selectedRole={selectedRole}
        selectedDifficulty={selectedDifficulty}
        onChangeRole={setSelectedRole}
        onChangeDifficulty={setSelectedDifficulty}
      />
      <ChampionList championList={filteredChampions} />
    </>
  );
};

export const query = graphql`
  query ChampionsQuery {
    pageMetadata: pageMetaData {
      title
      description
      image_url
    }
  }
`;

export default Champions;
