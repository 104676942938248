import { gsap } from "gsap";
import React, { useEffect, useRef } from "react";
import { Link } from "gatsby-plugin-intl";
import styles from "./LatestReleasesCard.module.scss";
import { isIE } from "../../../../utils/browser-detect";
import { ContentstackOption, ContentstackUrl } from "../../../../data/types/Contentstack";

interface LatestReleasesCardProps {
  title: string;
  category: Array<ContentstackOption>;
  image: ContentstackUrl;
  href: ContentstackUrl;
  isActiveCard?: boolean;
  animationDelay?: number;
  className?: string;
}

export const LatestReleasesCard: React.FC<LatestReleasesCardProps> = ({
  title,
  category,
  image,
  href,
  isActiveCard,
  animationDelay = 0,
  className = "",
}) => {
  const borderDetailRef = useRef<HTMLDivElement>(null);
  const titleWrapperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (isActiveCard || animationDelay > 0) {
      if (!titleWrapperRef.current || !borderDetailRef.current) return;
      const tl = gsap.timeline({ detaults: { ease: "expo.out" } });
      const duration = 0.6;
      const dashDetail = borderDetailRef.current?.firstChild?.firstChild as HTMLSpanElement;
      const titleWrapperChilds = [...titleWrapperRef.current?.children];
      const initalWidth = borderDetailRef.current?.getBoundingClientRect().width;
      const initilHeight = borderDetailRef.current?.getBoundingClientRect().height;

      tl.set(borderDetailRef.current, { height: 1 });
      tl.from(titleWrapperChilds, { yPercent: 10, autoAlpha: 0, stagger: 0.3, duration: 1 }, animationDelay)
        .fromTo(borderDetailRef.current, { width: 0 }, { width: initalWidth, duration }, animationDelay)
        .to(borderDetailRef.current, { height: initilHeight, duration: duration - 0.1 }, animationDelay + duration)
        .from(dashDetail, { scaleY: 0, duration: duration - 0.1 }, `-=${duration - 0.1}`)
        .set([borderDetailRef.current, dashDetail], { clearProps: "all" });
    }
  }, [isActiveCard, animationDelay]);

  return (
    <div className={`${styles.cardWrapper} ${className}`}>
      <Link to={href.url} className={styles.card}>
        {isIE() ? (
          <span className={styles.ieImageWrapper} style={{ backgroundImage: `url(${image.url})` }} />
        ) : (
          <picture className={styles.imageWrapper}>
            <img className={styles.image} src={image.url} alt={`${title} thumbnail`} />
          </picture>
        )}
        <div className={styles.copyWrapper} ref={titleWrapperRef}>
          <h2 className={`label-04 ${styles.championName}`}>{category[0].title}</h2>
          <div className={styles.titleWrapper}>
            <h1 className={`heading-10 font-normal ${styles.cardTitle}`}>{title}</h1>
          </div>
        </div>
        <div className={styles.border} ref={borderDetailRef}>
          <div className={styles.detailBorder}>
            <span className={styles.dashDetail} />
          </div>
        </div>
      </Link>
    </div>
  );
};
