import React, { useEffect, useRef } from "react";
import styles from "./ChampionListCard.module.scss";
import { Link } from "gatsby-plugin-intl";
import { ContentstackChampionCard } from "../../../../data/types/Contentstack";
import { gsap } from "gsap";
import { useInView } from "react-intersection-observer";
import { useIsMobile } from "../../../../hooks/useIsMobile";

interface ChampionListCardProps {
  className?: string;
  cardContent: ContentstackChampionCard;
  href: string;
  index: number;
}

export const ChampionListCard: React.FC<ChampionListCardProps> = ({
  className = "",
  cardContent: { name, image },
  href,
  index,
}) => {
  const [observerRef, inView] = useInView({ triggerOnce: true, threshold: 0.5 });
  const cardRef = useRef<HTMLDivElement>(null);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!cardRef.current || !inView) return;

    const cardsPerRow = isMobile ? 2 : 4;
    const delay = (index % cardsPerRow) * 0.1;

    gsap.set(cardRef.current, { css: { visibility: "visible" } });
    gsap.fromTo(
      cardRef.current,
      { yPercent: 10, autoAlpha: 0 },
      { yPercent: 0, autoAlpha: 1, duration: 0.3, ease: "power1.out", delay },
    );
  }, [index, isMobile, inView]);

  return (
    <div ref={observerRef}>
      <div ref={cardRef} className={styles.animationWrapper}>
        <Link to={href} className={`${styles.championListCardWrapper} ${className}`}>
          <span className={styles.dashDetail} />

          <div className={styles.ieChampionImageWrapper}>
            <span className={styles.ieChampionImage} style={{ backgroundImage: `url(${image.url})` }} />
          </div>

          <picture className={styles.championImageWrapper}>
            <img className={styles.championImage} src={image.url} alt={name} />
          </picture>

          <div className={styles.infoWrapper}>
            <h3 className={`heading-10 ${styles.championName}`}>{name}</h3>
          </div>
        </Link>
      </div>
    </div>
  );
};
