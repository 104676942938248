import { gsap } from "gsap";

export const animateList = (element: HTMLUListElement | null) => {
  if (!element || !element.children.length) return;

  const tl = gsap.timeline({ paused: true });
  const childElements = [...element.children];
  tl.set(element, { autoAlpha: 1 });
  tl.from(childElements, { yPercent: 20, autoAlpha: 0, stagger: 0.2, duration: 2, ease: "expo.out", delay: 0.5 });
  return tl;
};
