import React, { useRef } from "react";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { useSlider } from "../../../hooks/useSlider";
import { LatestReleasesCard } from "../../content/cards/LatestReleasesCard/LatestReleasesCard";
import styles from "./LatestReleases.module.scss";
import { graphql } from "gatsby";
import { ContentstackUrl, ContentstackOption } from "../../../data/types/Contentstack";
import { useScrollObserver } from "../../../hooks/useScrollObserver";
import { animateList } from "./animations";

export interface LatestReleasesProps {
  latestReleases: {
    title: string;
    articles: Array<{
      title: string;
      category: Array<ContentstackOption>;
      featuredImage: {
        banner: ContentstackUrl;
      };
      url: ContentstackUrl;
    }>;
  };
}

export const LatestReleases: React.FC<LatestReleasesProps> = ({ latestReleases: { title, articles } }) => {
  const { sliderRef } = useSlider({ align: "start" });

  const isMobile = useIsMobile();
  const sliderListRef = useRef<HTMLUListElement | null>(null);
  const desktopListRef = useRef<HTMLUListElement | null>(null);

  const observerRef = useScrollObserver<HTMLUListElement | null>(
    animateList,
    isMobile ? sliderListRef : desktopListRef,
    {
      triggerOnce: true,
    },
  );

  return articles.length ? (
    <section className={styles.contentWrapper} ref={observerRef}>
      <div className={`container ${styles.latestReleases}`}>
        <div className={"content-wrapper dark"}>
          <h1 className={`label-04 ${styles.mainTitle}`}>{title}</h1>

          {isMobile ? (
            <div className={styles.sliderWrapper}>
              <div className={styles.slider} ref={sliderRef}>
                <ul className={styles.sliderContent} ref={sliderListRef}>
                  {articles.map(({ title, category, featuredImage: { banner }, url }, index) => (
                    <li key={index} className={styles.sliderItem}>
                      <LatestReleasesCard
                        className={styles.latestReleasesCard}
                        animationDelay={0.5 + index * 0.4}
                        title={title}
                        category={category}
                        image={banner}
                        href={url}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : (
            <ul className={styles.latestReleasesList} ref={desktopListRef}>
              {articles.map(({ title, category, featuredImage: { banner }, url }, index) => (
                <li key={index} className={styles.latestReleasesItem}>
                  <LatestReleasesCard
                    className={styles.latestReleasesCard}
                    animationDelay={0.5 + index * 0.4}
                    title={title}
                    category={category}
                    image={banner}
                    href={url}
                  />
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </section>
  ) : null;
};

export const query = graphql`
  fragment LatestReleases on Contentstack_champion_list {
    latestReleases: featured_news {
      title
      articles: articles_select {
        title
        category {
          title
        }
        featuredImage: banner_settings {
          banner {
            url
          }
        }
        url {
          url
        }
      }
    }
  }
`;
